import { Component, OnInit } from "@angular/core";
import { MailService } from '../mail.service';

@Component({
  selector: "app-dugunpaketi",
  templateUrl: "./dugunpaketi.component.html",
  styleUrls: ["./dugunpaketi.component.css"]
})
export class DugunpaketiComponent implements OnInit {
  source = [
    "Salon Dekorasyonu",
    "Orkestra",
    "Video Çekimi",
    "Video Ön Çekim",
    "Yedi Katlı Düğün Pastası",
    "Servis-Temizlik",
    "Sınırsız İçecek",
    "Sınırsız Meyve",
    "Yemek",
    "Çorba",
    "Meze",
    "Davul-Zurna",
    "Gelin Arabası",
    "Limuzin",
    "Halk Dansları",
    "Albüm",
    "Palyaço",
    "Sihirbaz",
    "Dansöz",
    "Online Düğün"
  ];

  desc = [
    "7 çeşit dekorasyonumuz içersinde istediğinizi seçebilirsiniz (<a href='/salonumuz' target='_blank'>Salonumuz</a> kısmından görüntüleyebilirsiniz.)",
    "Her yöreye uygun orkestra temin edilebilir.",
    
    "Çift kamera ile profesyonel seçim. Çekimler 4 Tuval (Leinwand)'den canlı yansıtılıyor.",
    
    "Gelin ve damat evden çıkarken çekim yapılıyor.",
   
    "Pastanızı, Hasret Pastanesi'nin websitesini ziyaret ederek seçebilirsiniz. <a href='http://www.hasret-pastanesi.de' target='_blank'>Tıklayınız</a>.",
 
    "Düğün büyüklüğüne göre her 100 misafire en az 1 garsonla hizmet veriyoruz.",
       
    "Cola, Fanta, Asitli- Asitsiz Su.",
   
    "En az 4 çeşit meyveyi sonuna kadar masalardan eksik etmiyoruz.",

    "seçebilirsiniz.",

   " Çorba çeşitleri...",

    "İstendikçe sınırsız. Mezeler; Acılı, Haydari ve Havuç ezmesinden ibarettir. Aynı zamanda servis tabağında Beyaz Peynir, Domates, Salatalık, Acı Biber ve Maydanoz servis edilir.",
   
    "Yöreye göre Davul-Zurna ekibi.",
  
    "S-Klasse Mecedes'le Gelin ve Damadı evlerinden alıp, ufak bir gezintiden sonra, salona getiriyoruz. Düğün bittikten sonra da evlerine bırakıyoruz. (<a href='/salonumuz' target='_blank'>Salonumuz</a> kısmından görüntüleyebilirsiniz.)",
 
    "İstediğiniz Limuzin(Limousine)'i seçebilirsiniz. <a href='http://www.luxus-limo.de' target='_blank'>Tıklayınız</a> ",

    "İstediğiniz yöreye ait halk oyunları.",

    "Foto albüm: 1 büyük panoramik albüm, 2 kaynana panoramik albümü ve 2 adet 90x140 tuval resim.",
    "2 saat boyunca çocuklara palyaço eğlencesi.",
  
    "Güzel bir sihirbazlık gösterisi.",
    "Dansöz",
    
    "Düğününüz kaydedilir ve internet sayfamıza kaydedilir siz de bunu, size verilecek şifre sayesinde, 2 hafta boyunca online olarak izleyebilirsiniz."
  ];

  confirmed = [];
  format = {
    add: "Listeye Ekle",
    remove: "Listeden Çıkar",
    all: "Hepsi",
    none: "Hiçbiri",
    direction: "left-to-right",
    draggable: true,
    locale: undefined
  };
  constructor(private mailService:MailService) {}

  ngOnInit() { }
  
  onSubmit() {
    let data = {
      name: (document.getElementById('paket_name') as HTMLInputElement).value,
      email: (document.getElementById('paket_email') as HTMLInputElement).value,
      telefon: (document.getElementById('paket_tel') as HTMLInputElement).value,
      geburtsdatum: (document.getElementById('paket_gdatum') as HTMLInputElement).value,
      anzahl: (document.getElementById('paket_anzahl') as HTMLInputElement).value,
      preis:(document.getElementById('paket_preis') as HTMLInputElement).value,
      message: (document.getElementById('paket_message') as HTMLTextAreaElement).value,
      liste: this.confirmed
      
    };


    if (data.name == undefined || data.email == undefined || !data.email.includes('@') || data.anzahl == undefined || data.preis == undefined) {
      alert("Lütfen gerekli alanları doldurunuz.")
   } else {

     
      this.mailService.paketSenden(data).subscribe(result => {
        console.log(result) ;
      },
        err => {
          alert("Bir Hata oluştu.") ;
        },
        () => {
          document.getElementById("msg").innerHTML = "Teşekkürler. Başvurunuz gönderildi.";
          alert('Teşekkürler. Düğün Paketi isteğiniz işleme kondu. En kısa sürede sizinle iletişime geçilecektir');
          location.href = 'anasayfa';
        }

      );
   }
  }

  showMes(event) {
    let tmp = this.desc[this.source.indexOf(event.target.innerText)];
    if (tmp == undefined) {
      document.getElementById("showDesc").innerHTML ="[***Listeden herhangi bir özelliğe tıkladığınızda özellik hakkında ayrıntılı bilgi burada görünecektir.***]"

    } else {
      document.getElementById("showDesc").innerHTML = tmp;

     }
    
  }
}
