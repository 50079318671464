import { Component, OnInit } from "@angular/core";
import { MailService } from "../mail.service";

@Component({
  selector: "app-iletisim",
  templateUrl: "./iletisim.component.html",
  styleUrls: ["./iletisim.component.css"]
})
export class IletisimComponent implements OnInit {
  data: any;
  
  constructor(private mailService: MailService) {
    
  }

  onSubmit(evt) {
    let data = {
      name: (document.getElementById('form_name') as HTMLInputElement).value,
      email:(document.getElementById('form_email') as HTMLInputElement).value,
      message: (document.getElementById('form_message') as HTMLTextAreaElement).value
      
    };
    if (data.name == undefined || data.email == undefined || !data.email.includes('@')) {
      alert("Lütfen gerekli alanları doldurunuz.");
    } else {
       
      this.mailService.kontaktSenden(data).subscribe(result => {
        console.log(result) ;
      },
        err => {
          alert("Bir Hata oluştu.") ;
        },
        () => {
          document.getElementById("msg").innerHTML = "Bizimle iletişim kurduğunuz için teşekkürler.";
          alert('Teşekkürler. İletişim formu başarıyla gönderildi.');
          location.href = 'anasayfa';
        }
      );
    }
  }
  ngOnInit() {}
}
