import { Component, OnInit } from "@angular/core";

import { MailService } from '../mail.service.js';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {

 
  constructor(private mailService:MailService) { }

  ngOnInit() { }
  
  getLive() {
    this.mailService.getLiveLink().subscribe(res => {
      open((<{id:number,link:string}>res).link);
    });
 
  }
}
