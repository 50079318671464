import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { SalonComponent } from "./salon/salon.component";
import { HizmetlerComponent } from "./hizmetler/hizmetler.component";
import { GaleriComponent } from "./galeri/galeri.component";
import { IletisimComponent } from "./iletisim/iletisim.component";
import { DugunpaketiComponent } from "./dugunpaketi/dugunpaketi.component";
import { ImpressumComponent } from "./impressum/impressum.component";
import { NotFoundComponent } from "./not-found/not-found.component";

const routes: Routes = [

  { path: "anasayfa", component: HomeComponent },
  { path: "salonumuz", component: SalonComponent },
  { path: "hizmetlerimiz", component: HizmetlerComponent },
  { path: "galeri", component: GaleriComponent },
  { path: "iletisim", component: IletisimComponent },
  { path: "dugunpaketi", component: DugunpaketiComponent },
  { path: "impressum", component: ImpressumComponent },
  { path: "impressum", component: ImpressumComponent },
  { path: "", redirectTo: "/anasayfa", pathMatch: "full" },
  { path: "**", component: NotFoundComponent }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
