import { Component, OnInit } from "@angular/core";


@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: []
})
export class AppComponent implements OnInit {
  title = "Eurosaal Düğün Salonu";
  year = new Date().getFullYear();
  constructor() {}

  ngOnInit(): void {}
}
