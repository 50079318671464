import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hizmetler',
  templateUrl: './hizmetler.component.html',
  styleUrls: ['./hizmetler.component.css']
})
export class HizmetlerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
