import { Component, OnInit } from '@angular/core';
import { MailService } from '../mail.service';

@Component({
  selector: 'app-galeri',
  templateUrl: './galeri.component.html',
  styleUrls: ['./galeri.component.css']
})
export class GaleriComponent implements OnInit {

  public instaData;
  constructor(private service:MailService) { }

  ngOnInit() {
    this.service.getInstaPosts().subscribe(r => this.instaData = r);
  }

  
}
