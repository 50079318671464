import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: 'root',
})
export class MailService {
  constructor(private http: HttpClient) {}

  kontaktSenden(data: any) {
    let fd = new FormData();
    for (const key in data) {
      fd.append(key, data[key]);
     }

    return this.http.post("assets/email.php?m=kontakt",  fd);
  }

  paketSenden(data: any) {
     
    let fd = new FormData();

    for (const key in data) {
      if (key == "liste") {
        let liste = "";
       for (let i = 0; i < data[key].length; i++) {
          liste+=data[key][i]+",<br>"
       }
       fd.append(key, liste);
      } else {
        fd.append(key, data[key]);
     }
   
   }
    
    

    return this.http.post("assets/email.php?m=paket", fd);
  }


  getLiveLink() {
    return this.http.get('assets/liveLink.json');
  }

  getInstaPosts() {
    let token = "8783244054.a5a9566.21e59dfb0a4b497d80ff0bc3beffd424";
    let  url = "https://api.instagram.com/v1/users/self/media/recent/?access_token="+token+"&count=30";
   
     return this.http.get(url);
    
  }
}
