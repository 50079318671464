import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";


import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { SalonComponent } from "./salon/salon.component";
import { HizmetlerComponent } from "./hizmetler/hizmetler.component";
import { GaleriComponent } from "./galeri/galeri.component";
import { IletisimComponent } from "./iletisim/iletisim.component";
import { DugunpaketiComponent } from "./dugunpaketi/dugunpaketi.component";
import { ImpressumComponent } from "./impressum/impressum.component";
import { NotFoundComponent } from "./not-found/not-found.component";
import { MailService } from "./mail.service";
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from "@angular/forms";
import { AngularDualListBoxModule } from "angular-dual-listbox";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SalonComponent,
    HizmetlerComponent,
    GaleriComponent,
    IletisimComponent,
    DugunpaketiComponent,
    ImpressumComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    AngularDualListBoxModule 
  ],
  providers: [MailService],
  bootstrap: [AppComponent]
})
export class AppModule {}
